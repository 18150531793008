import React, { useEffect, useState, useCallback } from 'react';
import { Upload, Form, Select, Button, message, Progress, Card, Table, Alert, Space } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import api from '../../services/api';
import type { UploadFile } from 'antd/es/upload/interface';

const { Dragger } = Upload;

interface ProcessedBook {
    fileName: string;
    author: string;
    pageCount: number;
    creator: string;
    title: string;
    producer: string;
}

interface BookSummary {
    title: string;
    reason: string;
}

interface ProcessResponse {
    summary: {
        total: number;
        created: number;
        existing: number;
        failed: number;
    };
    processedBooks: ProcessedBook[];
    existingBooks: BookSummary[];
    failedBooks: BookSummary[];
}

interface Category {
    value: string;
    label: string;
    parent_id?: string | null;
}

const CHUNK_SIZE = 512 * 1024; // 1MB chunks
const UPLOAD_TIMEOUT = 3600000; // 1 hour timeout for uploads
const PROCESSING_TIMEOUT = 7200000; // 2 hours timeout for processing

const BulkBooksCreate: React.FC = () => {
    const [form] = Form.useForm();
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [processResult, setProcessResult] = useState<ProcessResponse | null>(null);
    const [categories, setCategories] = useState<Category[]>([]);
    const [subCategories, setSubCategories] = useState<Category[]>([]);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [currentChunk, setCurrentChunk] = useState(0);
    const [totalChunks, setTotalChunks] = useState(0);

    const loadCategories = async () => {
        try {
            const response = await api.get('/library/categorias');
            const cats = response.data.data;
            const data_categorias = cats.map((item: any) => ({
                value: item.id,
                label: item.name,
                parent_id: item.parent_id,
            }));
            setCategories(data_categorias);
        } catch (error) {
            console.error('Erro ao carregar categorias:', error);
            message.error('Erro ao carregar categorias');
        }
    };

    useEffect(() => {
        loadCategories();
    }, []);

    const handleCategoryChange = useCallback(
        (value: string) => {
            const subs = categories
                ?.filter((cat) => cat.parent_id === value)
                .map((cat) => ({
                    value: cat.value,
                    label: cat.label,
                }));
            setSubCategories(subs);
            form.setFieldValue('subcategoryId', undefined);
        },
        [categories, form],
    );

    const handleUpload = async (options: any) => {
        const { file, onSuccess, onError, onProgress } = options;
    
        if (!file) {
            message.error('Arquivo inválido');
            return;
        }
    
        try {
            setUploading(true);
            const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
            setTotalChunks(totalChunks);
    
            // Upload all chunks
            for (let i = 0; i < totalChunks; i++) {
                setCurrentChunk(i);
                const chunk = file.slice(i * CHUNK_SIZE, Math.min((i + 1) * CHUNK_SIZE, file.size));
    
                const response = await uploadChunk(chunk, file, i, totalChunks);
                console.log(`Chunk ${i + 1}/${totalChunks} uploaded:`, response);
    
                // Update progress
                if (response.progress) {
                    setUploadProgress(response.progress);
                    onProgress?.({ percent: response.progress });
                }
    
                // Check if this is the final chunk and upload is complete
                if (response.data?.file && i === totalChunks - 1) {
                    console.log('Upload completed successfully');
                    onSuccess?.(response);
                    setUploadSuccess(true);
                    message.success('Upload realizado com sucesso!');
                    break;
                }
    
                // If there's verification info, log it but don't throw error
                if (response.verification) {
                    console.log('Upload verification status:', response.verification);
                }
            }
        } catch (error: any) {
            console.error('Upload error:', error);
            onError?.(error);
            message.error(error.response?.data?.message || error.message || 'Falha no upload');
            setUploadSuccess(false);
        } finally {
            setUploading(false);
            setCurrentChunk(0);
            setTotalChunks(0);
            setUploadProgress(0);
        }
    };
    
    const uploadChunk = async (chunk: Blob, file: File, chunkIndex: number, totalChunks: number): Promise<any> => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onloadend = async () => {
                const base64Chunk = reader.result?.toString().split(',')[1];
                if (!base64Chunk) {
                    reject(new Error('Erro ao converter chunk'));
                    return;
                }
    
                const formData = new FormData();
                formData.append('chunk', base64Chunk);
                formData.append('fileName', file.name);
                formData.append('totalChunks', totalChunks.toString());
                formData.append('currentChunk', chunkIndex.toString());
                formData.append('fileSize', file.size.toString());
    
                try {
                    const response = await api.post('/library/book.upload.bulk', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        timeout: UPLOAD_TIMEOUT,
                        timeoutErrorMessage: 'O upload excedeu o tempo limite.'
                    });
                    resolve(response.data);
                } catch (error: any) {
                    console.error('Chunk upload error:', error);
                    if (error.code === 'ECONNABORTED') {
                        reject(new Error('O upload excedeu o tempo limite. Por favor, tente novamente.'));
                    } else {
                        reject(error);
                    }
                }
            };
            reader.readAsDataURL(chunk);
        });
    };

    const handleProcessBooks = async () => {
        try {
            const values = await form.validateFields();

            if (!fileList[0] || !uploadSuccess) {
                message.error('Por favor, faça o upload do arquivo primeiro');
                return;
            }

            setUploading(true);
            const bulkCreateResponse = await api.post('/library/books/create/bulk', 
                {
                    categoryId: String(values.categoryId),
                    subcategoryId: String(values.subcategoryId),
                    fileName: fileList[0].response?.data?.file,
                },
                {
                    timeout: PROCESSING_TIMEOUT,
                    timeoutErrorMessage: 'O processamento demorou muito tempo. Por favor, tente novamente.'
                }
            );

            setProcessResult(bulkCreateResponse.data);
            message.success('Processamento concluído!');
        } catch (error: any) {
            console.error('Processing error:', error);
            if (error.code === 'ECONNABORTED') {
                message.error('O processamento excedeu o tempo limite. Por favor, tente novamente.');
            } else {
                message.error(error.response?.data?.message || 'Falha ao processar os livros');
            }
        } finally {
            setUploading(false);
        }
    };


    const successColumns = [
        {
            title: 'Título',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Autor',
            dataIndex: 'author',
            key: 'author',
        },
        {
            title: 'Páginas',
            dataIndex: 'pageCount',
            key: 'pageCount',
        },
        {
            title: 'Nome do Arquivo',
            dataIndex: 'fileName',
            key: 'fileName',
        },
    ];

    const summaryColumns = [
        {
            title: 'Título',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Motivo',
            dataIndex: 'reason',
            key: 'reason',
        },
    ];

    const uploadProps = {
        name: 'file',
        multiple: false,
        fileList,
        accept: '.zip',
        customRequest: handleUpload,
        beforeUpload: (file: File) => {
            const isZip = file.type === 'application/zip' || file.type === 'application/x-zip-compressed' || file.name.toLowerCase().endsWith('.zip');

            if (!isZip) {
                message.error('Você só pode fazer upload de arquivos ZIP!');
                return false;
            }

            // Check file size (10GB max)
            const maxSize = 10 * 1024 * 1024 * 1024; // 10GB in bytes
            if (file.size > maxSize) {
                message.error('O arquivo é muito grande. O tamanho máximo permitido é 10GB.');
                return false;
            }

            return true;
        },
        onChange: (info: any) => {
            setFileList(info.fileList.slice(-1));

            if (info.file.status === 'done') {
                setUploadSuccess(true);
            } else {
                setUploadSuccess(false);
            }
        },
        onRemove: () => {
            setFileList([]);
            setProcessResult(null);
            setUploadSuccess(false);
            setUploadProgress(0);
            setCurrentChunk(0);
            setTotalChunks(0);
        },
    };

    return (
        <div className="p-6 bg-white rounded-lg shadow">
            <h2 className="text-2xl font-bold mb-6">Upload em Massa de Livros</h2>

            <Form form={form} layout="vertical">
                <Form.Item name="categoryId" label="Categoria" rules={[{ required: true, message: 'Por favor, selecione uma categoria' }]}>
                    <Select placeholder="Selecione a categoria" onChange={handleCategoryChange} options={categories?.filter((cat) => !cat.parent_id)} />
                </Form.Item>

                <Form.Item name="subcategoryId" label="Sub Categoria" rules={[{ required: true, message: 'Por favor, selecione uma sub categoria' }]}>
                    <Select placeholder="Selecione a sub categoria" options={subCategories} disabled={!form.getFieldValue('categoryId')} />
                </Form.Item>

                <Card className="mb-6">
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Clique ou arraste o arquivo ZIP para esta área</p>
                        <p className="ant-upload-hint">O arquivo ZIP deve conter os livros em PDF que você deseja fazer upload em massa</p>
                    </Dragger>

                    {uploadProgress > 0 && (
                        <div className="mt-4">
                            <Progress percent={uploadProgress} />
                            {totalChunks > 0 && (
                                <p className="text-sm text-gray-500 mt-2">
                                    Enviando parte {currentChunk + 1} de {totalChunks}
                                </p>
                            )}
                        </div>
                    )}
                </Card>

                <Button onClick={handleProcessBooks} disabled={!uploadSuccess || uploading} loading={uploading} className="mb-6">
                    {uploading ? 'Processando...' : 'Processar Livros'}
                </Button>

                {processResult && (
                    <div className="mt-6 space-y-6">
                        <Space direction="vertical" className="w-full">
                            <Alert
                                style={{ background: 'white' }}
                                message="Resumo do Processamento"
                                description={
                                    <ul className="list-none p-0 m-0">
                                        <li>Total de livros processados: {processResult.summary.total}</li>
                                        <li>Livros criados com sucesso: {processResult.summary.created}</li>
                                        <li>Livros já existentes: {processResult.summary.existing}</li>
                                        <li>Livros com falha: {processResult.summary.failed}</li>
                                    </ul>
                                }
                                type="info"
                                showIcon
                            />

                            {processResult.processedBooks.length > 0 && (
                                <div>
                                    <h3 className="text-xl font-semibold mb-4">Livros Processados com Sucesso</h3>
                                    <Table dataSource={processResult.processedBooks} columns={successColumns} rowKey="fileName" pagination={false} />
                                </div>
                            )}

                            {processResult.existingBooks.length > 0 && (
                                <div>
                                    <h3 className="text-xl font-semibold mb-4">Livros Já Existentes</h3>
                                    <Table dataSource={processResult.existingBooks} columns={summaryColumns} rowKey="title" pagination={false} />
                                </div>
                            )}

                            {processResult.failedBooks.length > 0 && (
                                <div>
                                    <h3 className="text-xl font-semibold mb-4">Livros com Falha no Processamento</h3>
                                    <Table dataSource={processResult.failedBooks} columns={summaryColumns} rowKey="title" pagination={false} />
                                </div>
                            )}
                        </Space>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default BulkBooksCreate;
